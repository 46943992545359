<template>
  <v-card flat class="flex" outlined min-height="200" :loading="loadingThresholds || updatingPatientThresholds">
    <add-threshold-dialog @createdThreshold="submitThreshold" ref="addThreshold" />

    <v-card flat color="transparent" class="pa-4">
      <v-row align="center" justify="space-between" class="mb-2" no-gutters>
        <span class="font-weight-bold text-h6">
          {{ descriptionTitle }}
        </span>
        <v-btn color="primary" text depressed @click="addThreshold">
          <v-icon left>mdi-file-document-box-plus-outline</v-icon>
          add Rule
        </v-btn>
      </v-row>
      <v-row class="" no-gutters>
        <span class="font-weight-light text-body-1">
          {{ description }}
        </span>
      </v-row>
    </v-card>

    <v-simple-table :key="boomKey" style="width: 100%; background-color:transparent">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Metric</th>
            <th class="text-left">Operator</th>
            <th class="text-left">Value</th>
            <th class="text-left">Remove</th>
          </tr>
        </thead>

        <tbody v-if="rulesArray.length > 0">
          <tr v-for="(item, index) in rulesArray" :key="index">
            <td class="mt-2 mb-2">
              {{ thresholdDisplayName(item.metric) }}
            </td>
            <td>
              {{ item.operator }}
            </td>
            <td>
              {{ item.value }}
            </td>
            <td style="max-width: 80px; width: 80px;">
              <v-layout row justify-center>
                <v-btn @click="removeThreshold(item)" color="red" icon>
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-layout>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-layout
      class="mx-0 mt-4 mb-4"
      v-if="rulesArray.length === 0"
      style="min-width: 100%; opacity: 0.4"
      row
      justify-center
    >
      No Rules Set
    </v-layout>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import AddThresholdDialog from '@/components/dialogs/AddThresholdDialog'
import HelperMixin from '@/core/mixins/HelperMixin'
import consts from '@/consts'

export default {
  mixins: [HelperMixin],
  data() {
    return {
      boomKey: 0,
      selectedStartDate: 1,
      autoCounting: false,
      partnerObject: {},
      rulesTable: 0,
      loadingThresholds: false,
      rulesArray: [],
    }
  },
  components: {
    AddThresholdDialog,
  },
  watch: {
    patient() {
      this.createRulesArray()
    },
    partner() {
      this.createRulesArray()
    },
  },
  computed: {
    ...mapState('auth', ['partner']),
    ...mapState('auth', ['configuration', 'appTheme']),
    ...mapState('patient', ['patient', 'updatingPatientThresholds']),
    partnerMode() {
      if (this.partner && this.$route.name === 'Partner') {
        return true
      }
      return false
    },
    rpmThresholds() {
      return this.partner.thresholds
    },
    description() {
      if (this.partnerMode) {
        return 'Global threshold alert rules are applied to the entire patient population. When a threshold alert condition is met, the alert will be applied. Threshold values set on the patient level will take precedence over these global values.'
      }
      return 'Threshold alert rules are applied to the patient monitoring data entering the chart. When a threshold alert condition is met, the alert will be applied. Threshold values set on the patient level will take precedence over global values.'
    },
    descriptionTitle() {
      if (this.partnerMode) {
        return 'Global Threshold Alert Rules'
      }
      return 'Threshold Alert Rules'
    },
  },
  methods: {
    ...mapActions('auth', ['updatePartnerSettings', 'setPartnerThresholdSettings', 'updatePartnerObject']),
    ...mapActions('patient', ['setPatientThresholdSettings', 'patientDataAccessLog']),
    addThreshold() {
      this.$refs.addThreshold.open()
    },
    thresholdDisplayName(metric) {
      const metricDetails = consts.metricList[metric]
      if (metricDetails) {
        return metricDetails.longName
      } else {
        return metric
      }
    },
    createRulesArray() {
      // this should be done onload and every time there is an update
      let rawRules = {}
      let rulesToReturn = []

      if (this.partnerMode) {
        rawRules = this.partner.defaultPatientEventRules
      } else {
        rawRules = this.patient.eventRules
      }
      let keys = Object.keys(rawRules)
      keys.forEach(key => {
        if (rawRules[key].above) {
          let item = { metric: key }
          item.operator = 'Above'
          if (rawRules[key].above.value) {
            item.value = this.computeThresholdValue({
              valueInput: rawRules[key].above.value,
              metric: key,
            })
          } else if (rawRules[key].above.systolicValue) {
            item.value = rawRules[key].above.systolicValue + '/' + rawRules[key].above.diastolicValue
          }
          rulesToReturn.push(item)
        }
        if (rawRules[key].below) {
          let item = { metric: key }
          item.operator = 'Below'
          if (rawRules[key].below.value) {
            item.value = this.computeThresholdValue({
              valueInput: rawRules[key].below.value,
              metric: key,
            })
          } else if (rawRules[key].below.systolicValue) {
            item.value = rawRules[key].below.systolicValue + '/' + rawRules[key].below.diastolicValue
          }
          rulesToReturn.push(item)
        }

        if (rawRules[key].isTrue) {
          let item = { metric: key }
          item.operator = 'Equals'
          if (rawRules[key].isTrue) {
            item.value = 'Possible Afib'
          }
          rulesToReturn.push(item)
        }

        if (rawRules[key].isFalse) {
          let item = { metric: key }
          item.operator = 'Equals'
          if (rawRules[key].isFalse) {
            item.value = 'No Afib Detected'
          }
          rulesToReturn.push(item)
        }
      })
      this.rulesArray = rulesToReturn
    },
    computeThresholdValue(threshold) {
      let metric = threshold.metric
      let valueInput = threshold.valueInput
      let value = 0
      if (metric === 'MEASUREMENT_BODY_TEMPERATURE') {
        // convert form degrees to celcius
        value = (valueInput * 9) / 5 + 32
      } else if (metric === 'MEASUREMENT_BODY_WEIGHT') {
        // convert form degrees to celcius
        value = Math.round(10 * (valueInput * 2.205)) / 10
      } else {
        value = parseFloat(valueInput)
      }
      return value
    },
    submitThreshold(payload) {
      console.log('submitting threshold')
      console.log(payload)
      if (this.partnerMode) {
        if (this.partner.defaultPatientEventRules[payload.metricName]) {
          if (payload[payload.metricName].above) {
            this.partner.defaultPatientEventRules[payload.metricName].above = payload[payload.metricName].above
          } else if (payload[payload.metricName].below) {
            this.partner.defaultPatientEventRules[payload.metricName].below = payload[payload.metricName].below
          } else {
            this.partner.defaultPatientEventRules[payload.metricName] = payload[payload.metricName]
          }
        } else {
          this.partner.defaultPatientEventRules[payload.metricName] = payload[payload.metricName]
        }
        this.boomKey = this.boomKey + 1
        this.updatePartnerObject()
        this.createRulesArray()
      } else {
        if (this.patient.eventRules[payload.metricName]) {
          if (payload[payload.metricName].above) {
            this.patient.eventRules[payload.metricName].above = payload[payload.metricName].above
          } else if (payload[payload.metricName].below) {
            this.patient.eventRules[payload.metricName].below = payload[payload.metricName].below
          } else {
            this.patient.eventRules[payload.metricName] = payload[payload.metricName]
          }
        } else {
          this.patient.eventRules[payload.metricName] = payload[payload.metricName]
        }
        let dataToSet = {}
        dataToSet.patient = this.patient._id
        dataToSet.thresholds = this.patient.eventRules
        this.setPatientThresholdSettings(dataToSet)

        this.createRulesArray()
        this.patientDataAccessLog({
          patient: this.patient._id,
          destination: this.thresholdDisplayName(payload.metricName) + ' threshold alert rules updated',
        })
      }
    },

    removeThreshold(item) {
      if (this.partnerMode) {
        if (item.operator === 'Above') {
          delete this.partner.defaultPatientEventRules[item.metric].above
        } else if (item.operator === 'Below') {
          delete this.partner.defaultPatientEventRules[item.metric].below
        } else {
          delete this.partner.defaultPatientEventRules[item.metric]
        }
        this.updatePartnerObject()
        this.createRulesArray()
      } else {
        if (item.operator === 'Above') {
          delete this.patient.eventRules[item.metric].above
        } else if (item.operator === 'Below') {
          delete this.patient.eventRules[item.metric].below
        } else {
          delete this.patient.eventRules[item.metric]
        }
        let dataToSet = {}
        dataToSet.patient = this.patient._id
        dataToSet.thresholds = this.patient.eventRules
        this.setPatientThresholdSettings(dataToSet)
        this.createRulesArray()
        this.patientDataAccessLog({
          patient: this.patient._id,
          destination: this.thresholdDisplayName(item.metric) + ' threshold alert rules updated',
        })
      }
    },
  },
  mounted() {
    // this.displayedRulesArray()
    if (this.patient || this.partner) {
      this.createRulesArray()
    }
  },
}
</script>

<style></style>
